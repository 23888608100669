<template>
  <el-dialog  :title="title"
              :visible.sync="isOpenModal"
              :close-on-click-modal="false"
              width="800px"
              @closed="closeModal">
    <div style="width: 90%;">
      <ch-table
          :render-option="tableOption"
          :data="tableList"
          :props="{ width: '100%', height: '100%', size: 'mini' }"
      >
        <template slot="index" slot-scope="scope">
          <div class="index">{{ scope.row.index }}</div>
        </template>
      </ch-table>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data(){
    return {
      isOpenModal:false,
      title:'',
      tableList:[],
    }
  },
  computed:{
    tableOption: function () {
      return [
        {column: "slot", label: "排名", slotName: "index",},
        {column: "text", label: "姓名", prop: "staffName", showOverflowTooltip: true,},
        {column: "text", label: "数量", prop: "count", showOverflowTooltip: true,},
      ]
    }
  },
  methods:{
    openWatch(data,title){
      this.title = title
      this.isOpenModal = true
      this.tableList = data
    },
    closeModal(){
      this.isOpenModal = false
    }
  }
}
</script>
<style scoped lang="scss">
.index{
  background: #F0F2F5;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  text-align: center;
  line-height: 26px;
  margin-right: 30px;
  color: rgba(0, 0, 0, 0.65);
}
.el-table__row:nth-child(1) .index{
  background: #157AFE;
  color: #FFFFFF;
}
.el-table__row:nth-child(2) .index{
  background: #FFC107;
  color: #FFFFFF;
}
.el-table__row:nth-child(3) .index{
  background: #FF5722;
  color: #FFFFFF;
}
</style>